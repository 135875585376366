import React from "react";

interface IHomePageSectionsProps {
  sections: ISectionProps[];
}

interface ISectionProps {
  title: string;
  description: string | React.ReactNode;
  children: React.ReactNode;
}

const HomePageSections: React.FC<IHomePageSectionsProps> = ({ sections }) => {
  return (
    <>
      {sections.map((section) => {
        return (
          <div key={section.title}>
            <div className="pb-6">
              <div className="font-bold text-xl">{section.title}</div>
              <div>{section.description}</div>
            </div>
            {section.children}
          </div>
        );
      })}
    </>
  );
};

export default HomePageSections;
