import { navigate } from "gatsby";
import React, { useEffect } from "react";
import {
  ChevronRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import Spinner from "../components/Spinner";

import headerImage from "../assets/northstar/bg_img.png";
import resourcesIcon from "../assets/northstar/resources.png";

import ToolsComponent from "../components/Tools";
import InsightsComponent from "../components/Insights";
import NorthstarPageLayout from "../components/NorthstarPageLayout";
import useNorthstar from "../hooks/northstarHook";
import { useAuthContext } from "../contexts/authContext";
import FeaturedDatasets from "../components/FeaturedDatasets";
import HomePageSections from "../components/HomePageSections";
import { getTimeDifference } from "../utils";

const Home: React.FC = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const { data, error, isLoading } = useNorthstar(
    "/api/files?filters[documentType][$eq]=Resource",
    accessToken,
    { method: "get" },
  );

  useEffect(() => {
    let ignore = false;

    if (error?.status === 401 && !ignore) clearIdentity();

    return () => {
      ignore = true;
    };
  }, [error?.status]);

  return (
    <NorthstarPageLayout title="Northstar Home">
      <>
        <div className="flex gap-x-3 mb-10">
          <div
            className="h-[450px] grow rounded flex items-center shadow-lg"
            style={{
              backgroundImage: `url(${headerImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "bottom",
            }}
          >
            <div className="pl-6 w-full">
              <div className="text-blue text-7xl font-title mb-4">
                Find the Northstar
              </div>
              <div className="">
                Our team has years of experience taking great companies<br></br>
                on a journey to new heights.
              </div>
            </div>
          </div>
          <div className="h-[450px] w-[450px] border-gray-light border rounded bg-white shadow-lg">
            <div className="p-4">
              <div className="flex items-end">
                <img src={resourcesIcon} className="h-7 w-7"></img>
                <div className="font-bold pl-2 pb-1">Resources</div>
                <div className="grow"></div>
                <div
                  className="font-semibold text-end pb-1 text-blue-light cursor-pointer"
                  onClick={() => navigate("/resources")}
                >
                  View All
                </div>
                <div className="pb-[0.35rem]">
                  <ChevronRightIcon className="text-blue-light h-[1.2rem] cursor-pointer"></ChevronRightIcon>
                </div>
              </div>
              <div className="mt-3">
                <Spinner active={isLoading} />
                {(data?.data || []).map((resource) => {
                  return (
                    <div
                      className={`my-4 flex justify-between items-center cursor-pointer hover:text-blue-light ${
                        data?.data.length !== 4
                          ? "border-b border-b-gray-light pb-3"
                          : ""
                      }`}
                      onClick={() =>
                        navigate(`/resources/${resource.attributes.uuid}`)
                      }
                      key={resource.attributes.uuid}
                    >
                      <div>
                        <div className="font-semibold mb-1">
                          {resource.attributes.displayName ||
                            resource.attributes.name}
                        </div>
                        <div className="flex text-sm">
                          <div>
                            {getTimeDifference(resource.attributes.createdAt)}
                          </div>
                        </div>
                      </div>
                      <ChevronRightIcon className="h-[1.2rem] cursor-pointer"></ChevronRightIcon>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <HomePageSections
          sections={[
            {
              title: "Tools",
              description:
                "Interactive data tools allow you to create insights at-scale using our data and models, then easily operationalize those insights​.",
              children: <ToolsComponent />,
            },
            {
              title: "Insights",
              description:
                "Insights provide access to curated content and playbooks from experts on our Data Science, Go-to-Market and Talent teams.",
              children: <InsightsComponent />,
            },
            {
              title: "Datasets",
              description: (
                <div className="flex flex-wrap">
                  <div>
                    The datasets page provides a searchable collection of
                    commercial data that we have encountered. &nbsp;
                  </div>
                  <div className="mb-3 flex items-center">
                    <ExclamationCircleIcon className="h-5 text-orange mr-1" />
                    <div className="">
                      The records on this page are strictly for data discovery
                      and do not provide access to the data.
                    </div>
                  </div>
                </div>
              ),
              children: <FeaturedDatasets />,
            },
          ]}
        />
      </>
    </NorthstarPageLayout>
  );
};

export default Home;
