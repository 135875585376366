import { DateTime } from "luxon";
import { DocumentAttributes, TickerAttributes } from "./types/cms";

export const parseDatasetTag = (
  tag: string | string[] | null,
  translations: { [key: string]: string } = {},
) => {
  if (!tag) return "";

  if (typeof tag === "object") {
    const output = [];
    for (const t of tag)
      output.push(
        translations[t] || t.charAt(0) + t.substring(1).toLowerCase(),
      );
    return output.join(", ");
  }

  if (translations[tag]) return translations[tag];

  const splitTag = tag.split("_");
  let output = "";

  for (const word of splitTag) {
    output += word.charAt(0) + word.substring(1).toLowerCase() + " ";
  }
  return output.trim();
};

export const getTimeDifference = (lastUpdated: string) => {
  const currentTime = Date.now();
  const diffTime = currentTime - new Date(lastUpdated);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 1) return `last updated ${diffDays} days ago`;
  else if (diffDays === 1) return `last updated ${diffDays} day ago`;
  else {
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    if (diffMinutes < 60) {
      return `last updated ${diffMinutes} minutes ago`;
    } else {
      const diffHours = Math.floor(diffMinutes / 60);
      if (diffHours > 1) return `last updated ${diffHours} hours ago`;
      else return `last updated ${diffHours} hour ago`;
    }
  }
};

export const getFormattedReportMetada = (
  report: DocumentAttributes,
  associated_ticker?: TickerAttributes,
) => {
  const {
    report_id,
    ticker,
    company_name,
    report_date,
    report_start_date,
    jsondocument,
    logo_url,
  } = report;
  const { company_name: ticker_company_name, logo_url: ticker_logo_url } =
    associated_ticker || {};
  const companyName = `${ticker_company_name || company_name || ""}`;
  const reportDate = report_date
    ? DateTime.fromISO(report_date).toFormat("dd MMM yyyy")
    : "";
  const reportingPeriodTitle =
    report_start_date && report_date
      ? `(${DateTime.fromISO(report_start_date).toFormat(
          "MMM yyyy",
        )} - ${DateTime.fromISO(report_date).toFormat("MMM yyyy")})`
      : "";
  const reportingPeriod =
    report_start_date && report_date
      ? `AI Summary : ${DateTime.fromISO(report_start_date).toFormat(
          "dd MMM yyyy",
        )} - ${DateTime.fromISO(report_date).toFormat("dd MMM yyyy")}`
      : "";
  const title = `${companyName} AI Insights ${reportingPeriodTitle}`;
  const overview = jsondocument?.overview?.content || "";
  const encodedReportId = encodeURIComponent(report_id);
  return {
    ticker: ticker || "",
    companyName,
    reportDate,
    title,
    reportingPeriod,
    overview,
    encodedReportId,
    logo_url: ticker_logo_url || logo_url,
    jsondocument,
  };
};

export type PeriodKey =
  | "last_week"
  | "last_month"
  | "last_3_months"
  | "last_6_months"
  | "year_to_date"
  | "all_time";

export const getPeriodStartDate = (periodKey: PeriodKey): Date => {
  const date = new Date();
  const currentYear = date.getFullYear();

  switch (periodKey) {
    case "last_week": {
      const lastSunday = new Date(
        date.setDate(date.getDate() - date.getDay() - 1),
      );
      lastSunday.setHours(0, 0, 0, 0); // Start of the day
      return lastSunday;
    }
    case "last_month": {
      const firstDayLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1,
      );
      return firstDayLastMonth;
    }
    case "last_3_months": {
      const firstDayLast3Month = new Date(
        date.getFullYear(),
        date.getMonth() - 3,
        1,
      );
      return firstDayLast3Month;
    }
    case "last_6_months": {
      const firstDayLast6Month = new Date(
        date.getFullYear(),
        date.getMonth() - 6,
        1,
      );
      return firstDayLast6Month;
    }
    case "year_to_date": {
      const firstDayOfYear = new Date(currentYear, 0, 1); // January 1st of current year
      return firstDayOfYear;
    }
    case "all_time":
      return new Date(0); // Unix epoch time
    default:
      return date; // Fallback to current date if no match found
  }
};

export type SortingMethod = "market_cap" | "report_date";

export const getSortingParams = (sort: SortingMethod) => {
  switch (sort) {
    case "market_cap":
      return ["market_cap:desc", "report_date:desc", "ticker:asc"];
    case "report_date":
      return ["report_date:desc", "market_cap:desc", "ticker:asc"];
    default:
      return ["market_cap:desc", "report_date:desc", "ticker:asc"];
  }
};
