import React, { useEffect, useState } from "react";

import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import {
  DatasetAttributes,
  StrapiUnit,
  StrapiRecordSet,
} from "../../types/datasets";
import { navigate } from "gatsby";
import portfolioIcon from "../../assets/northstar/portfolio.png";
import Tiles from "../Tiles";

// if this number is ever pushed above 25, you need to modify the hook's
// query parameters to accommodate larger page sizes OR implement pagination.
const MAX_DATASETS_TO_FEATURE = 8;

const FeaturedDatasetsComponent: React.FC = () => {
  const { accessToken } = useAuthContext();

  const [datasets, setDatasets] = useState<StrapiRecordSet<DatasetAttributes>>(
    [],
  );

  const { data } = useNorthstar(`/api/catalog-datasets`, accessToken, {
    method: "get",
    params: {
      fields: ["name", "shortDescription"],
      populate: ["serviceSubcategory"],
      "filters[isFeatured][$eq]": true,
      "pagination[page]": 1,
      "pagination[pageSize]": MAX_DATASETS_TO_FEATURE,
      sort: ["createdAt:desc", "id:asc"],
    },
  });

  useEffect(() => {
    if (data?.data) {
      setDatasets(data?.data);
    }
  }, [data]);

  return (
    <Tiles
      items={(datasets || []).map((dataset: StrapiUnit<DatasetAttributes>) => {
        return {
          key: dataset.id,
          name: dataset.attributes.name,
          onClick: () => navigate(`/datasets/${dataset.id}`),
          description: dataset.attributes.shortDescription,
          tags: [
            {
              name: dataset.attributes.serviceSubcategory,
              id: dataset.id,
            },
          ],
          thumbnail: portfolioIcon,
        };
      })}
    />
  );
};

export default FeaturedDatasetsComponent;
